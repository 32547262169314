var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "col-10 col-md-7 mx-auto main-container-terms my-15" },
    [
      _c(
        "div",
        [
          _c("v-layout", { attrs: { cols: "12" } }, [
            _c("h2", { staticClass: "text-uppercase" }, [
              _vm._v(_vm._s(_vm.$t("TermAndConditions.Title"))),
            ]),
          ]),
          _c("v-layout", [
            _c("p", [_vm._v(_vm._s(_vm.$t("TermAndConditions.UpdateOn")))]),
          ]),
          _c("v-layout", [
            _c("p", [_vm._v(_vm._s(_vm.$t("TermAndConditions.P1")))]),
          ]),
          _c("v-layout", [
            _c("p", [_vm._v(_vm._s(_vm.$t("TermAndConditions.P2")))]),
          ]),
          _c("v-layout", [
            _c("p", [_vm._v(_vm._s(_vm.$t("TermAndConditions.P3")))]),
          ]),
        ],
        1
      ),
      _c("div", [
        _c("h3", [_vm._v(_vm._s(_vm.$t("TermAndConditions.Service.Title")))]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Service.P1")) + " "),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(_vm._s(_vm.$t("TermAndConditions.Account.Title")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Account.P1")) + " "),
        ]),
      ]),
      _c("div", [
        _c("h3", [_vm._v(_vm._s(_vm.$t("TermAndConditions.Opt.Title")) + " ")]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Opt.P1")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Opt.P2")) + " "),
        ]),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("TermAndConditions.Opt.P3")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("TermAndConditions.Opt.P4")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("TermAndConditions.Opt.P5")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("TermAndConditions.Opt.P6")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("TermAndConditions.Opt.P7")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("TermAndConditions.Opt.P8")) },
        }),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Opt.P9")) + " "),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.SchedulingJob.Title"))),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.SchedulingJob.P1")) + " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.SchedulingJob.P2")) + " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.SchedulingJob.P3")) + " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.SchedulingJob.P4")) + " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.SchedulingJob.P5")) + " "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(_vm._s(_vm.$t("TermAndConditions.DiagnosisJob.Title"))),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.DiagnosisJob.P1")) + " "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", [_vm._v(_vm._s(_vm.$t("TermAndConditions.Payments.Title")))]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Payments.P1")) + " "),
        ]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("TermAndConditions.Payments.P2")) + " "),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(
            _vm._s(_vm.$t("TermAndConditions.PromotionalCode.Title")) + " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.PromotionalCode.P1")) + " "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(
            _vm._s(_vm.$t("TermAndConditions.MustBe18OrOlder.Title")) + " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.MustBe18OrOlder.P1")) + " "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(
            " " + _vm._s(_vm.$t("TermAndConditions.IntellectualProperty.Title"))
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("TermAndConditions.IntellectualProperty.P1")) +
              " "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("TermAndConditions.LimitedWarranty.Title")) +
              " "
          ),
        ]),
        _c("p", [
          _vm._v(_vm._s(_vm.$t("TermAndConditions.LimitedWarranty.P1"))),
        ]),
      ]),
      _c("div", [
        _c("h3", [
          _vm._v(
            _vm._s(_vm.$t("TermAndConditions.LimitationOfLiability.Title"))
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("TermAndConditions.LimitationOfLiability.P1")) +
              " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("TermAndConditions.LimitationOfLiability.P2")) +
              " "
          ),
        ]),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("TermAndConditions.LimitationOfLiability.P3")) +
              " "
          ),
        ]),
      ]),
      _c("div", [
        _c("h3", [_vm._v(_vm._s(_vm.$t("TermAndConditions.General.Title")))]),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P1")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P2")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P3")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P4")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P5")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P6")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P7")),
          },
        }),
        _c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.$t("TermAndConditions.General.P8")),
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }